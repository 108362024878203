import { Search } from '@/components/pages/Search'
import SEO_PAGES from '@/constants/seo-pages'
import { withContext } from '@/lib/server/context'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

// Generate a random seed to use for sort=random
const genSeed = () => Math.floor(Math.random() * 10000)

export const getServerSideProps = withContext(
  async ({ locale, query }, { api, engineId }) => {
    const page = await api.getPage(engineId, SEO_PAGES.category, { slug: query.category })
    const { settings } = page
    const sort = query.sort ?? settings.searchSortkey
    const sortSeed = sort === 'random' ? genSeed() : null

    const [allProperties, properties] = await Promise.all([
      api.getAllProperties(engineId),
      api.searchProperties(engineId, {
        page_size: query.page_size,
        checkin: query.checkin,
        checkout: query.checkout,
        bedrooms: query.bedrooms,
        baths: query.baths,
        adults: query.adults,
        children: query.children,
        pets: query.pets,
        categories: query.categories,
        categories_by_slug: query.category,
        ...(query.flexible ? { extra: 'flexible_stays' } : {}),
        sort: sort,
        sort_seed: sortSeed
      })
    ])

    return {
      props: {
        ...(await serverSideTranslations(locale, ['search', 'common'])),
        allProperties,
        properties,
        page,
        settings,
        engineId,
        sort,
        sortSeed
      }
    }
  }
)

export default Search
